import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: `${_ctx.$route.path}/edit`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, { type: "primary" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.response?.data?.receiveBankCode ? '編輯': '上傳'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_el_descriptions, {
            column: 1,
            border: "",
            style: {"margin-bottom":"50px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_descriptions_item, { label: "收款銀行" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.response?.data?.receiveBankCode), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_descriptions_item, { label: "收款帳戶" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.response?.data?.receiveBankAccount), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_descriptions_item, { label: "收款人姓名" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.response?.data?.receiveName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_descriptions_item, { label: "收款人電話" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.response?.data?.receivePhone), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_descriptions_item, { label: "收款人地址" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.response?.data?.receiveAddress), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_descriptions_item, { label: "上傳更新日期" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.response?.data?.updatedAt)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}