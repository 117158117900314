
import { defineComponent } from 'vue';
import { formatLocalTime } from '@/utils/format-time';
import { usePayee } from '@/composables/api';

export default defineComponent({
  setup() {
    const { data: response, isLoading, isFetching } = usePayee({});

    return {
      response,
      isLoading,
      isFetching,
      formatLocalTime
    };
  }
});
